












































import { FrontendCommandLimit } from '@/includes/logic/CommandLimits/types'
import { BaseViewActionType } from '@/includes/types/Enums'
import {
  state,
  addLocalLimit,
  deleteLimit,
  isLimitNonePermission,
  newFrontendLimitModel,
  saveLimitsToConfig,
  setFrontendLimits,
  updateLocalLimit,
  resetSelectedLimit,
  isWhitelistSelected
} from '@/includes/logic/CommandLimits/CommandLimitsLogic'
import ModerationLimitList from '@/components/ModerationCommandLimits/ModerationLimitList.vue'
import ModerationLimitItem from '@/components/ModerationCommandLimits/ModerationLimitItem.vue'
import CurrentModule from '@/components/Modules/components/CurrentModule.vue'
import { ModuleTypesEnum } from '@/includes/logic/Modules/types/types'
import TempCurrentModuleMixin from '@/includes/logic/Modules/mixins/TempCurrentModuleMixin'

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

@Component({
  components: {
    CurrentModule,
    ModerationLimitItem,
    ModerationLimitList,
    PageTitle,
  },
  data() {
    return {
      saveLimitsToConfig,
      isLimitNonePermission,
      isWhitelistSelected,
      BaseViewActionType,
      deleteLimit,
      state,
    }
  }
})
export default class ManualModerationLimits extends Mixins(TempCurrentModuleMixin) {
  // isModalOpen = false
  //
  // action: BaseViewActionType = BaseViewActionType.New
  //
  // afterClose() {
  //   resetSelectedLimit()
  // }
  //
  // handleCreateClick() {
  //   this.action = BaseViewActionType.New
  //   state.selectedLimit = newFrontendLimitModel()
  //
  //   this.isModalOpen = true
  // }
  //
  // setLimitToEdit(limit: FrontendCommandLimit) {
  //   this.action = BaseViewActionType.Edit
  //   state.selectedLimit = cloneDeep(limit)
  //
  //   this.isModalOpen = true
  // }
  //
  // handleOkClick() {
  //   if (state.selectedLimit) {
  //     if (this.action === BaseViewActionType.New) {
  //       addLocalLimit(state.selectedLimit)
  //     }
  //
  //     if (this.action === BaseViewActionType.Edit) {
  //       updateLocalLimit(state.selectedLimit)
  //     }
  //
  //     this.resetState()
  //   }
  // }
  //
  // resetState() {
  //   this.isModalOpen = false
  //
  //   resetSelectedLimit()
  // }

  created() {
    this.setCurrentModule(ModuleTypesEnum.CommandLimitHandlerModule)
    //
    // setFrontendLimits()
    //
    // this.$baseTemplate.saveButton.show(() => {
    //   saveLimitsToConfig()
    // })
  }

  destroyed() {
    // this.$baseTemplate.saveButton.resetSaveButtonCallback()
  }
}
